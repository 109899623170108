import { FC } from 'react';

interface TimerDisplayProps {
  count: number;
}

export const TimerDisplay: FC<TimerDisplayProps> = ({ count }) => {
  const minute = ('0' + Math.floor(count / 60)).slice(-2);
  const second = ('0' + Math.floor(count % 60)).slice(-2);
  return (
    <div
      style={{
        fontSize: '30vw',
        fontFamily: "'Play', sans-serif",
        textAlign: 'center',
      }}
    >
      {minute}:{second}
    </div>
  );
};
