import React, { useState, useEffect } from 'react';
import { RewardDisplay } from './components/RewardDisplay';
import { ConfigDisplay } from './components/ConfigDisplay';
import { TimerDisplay } from './components/TimerDisplay';
import { Config } from './types';

export const App: React.FC = () => {
  const defaultConfig: Config = {
    periodConfigs: [
      {
        startCount: 100,
        price: 100,
      },
      {
        startCount: 40,
        price: 200,
      },
    ],
  };

  const [config, setConfig] = useState(defaultConfig);
  const [count, setCount] = useState(config.periodConfigs[0].startCount);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    if (isRunning) {
      const id = setInterval(() => {
        setCount((t) => t - 1);
      }, 1000);

      // クリーンアップ
      return () => {
        clearInterval(id);
      };
    }
  }, [isRunning]);

  useEffect(() => {
    if (isRunning && count <= 0) {
      setIsRunning(false);
    }
  }, [isRunning, count]);

  const reset = () => {
    setCount(config.periodConfigs[0].startCount);
    setIsRunning(false);
  };

  const toggleRunning = () => {
    setIsRunning(!isRunning);
  };

  return (
    <>
      <TimerDisplay count={count} />
      <RewardDisplay count={count} config={config} />
      <div>
        <button onClick={reset}>Reset</button>
      </div>
      <div>
        <button onClick={toggleRunning}>Pause/Continue</button>
      </div>
      <ConfigDisplay config={config} setConfig={setConfig} />
    </>
  );
};
