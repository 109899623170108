import { FC } from 'react';
import { Config } from '../types';

interface RewardDisplayProps {
  count: number;
  config: Config;
}

export const RewardDisplay: FC<RewardDisplayProps> = ({ count, config }) => {
  const reward = config.periodConfigs.reduce((sum, currentPeriod, i, arr) => {
    const periodElapsedCount = () => {
      // まだピリオドに入ってない場合
      if (currentPeriod.startCount < count) {
        return 0;
      }
      const periodEndCount = arr[i + 1]?.startCount;
      // ピリオドの期間を終えている場合
      if (periodEndCount && count <= periodEndCount) {
        return currentPeriod.startCount - periodEndCount;
      }
      // ピリオドの期間内の場合
      return currentPeriod.startCount - count;
    };
    const add = currentPeriod.price * periodElapsedCount();
    return sum + add;
  }, 0);

  return (
    <div
      style={{
        fontSize: '20vw',
        fontFamily: "'Nunito', sans-serif",
        textAlign: 'right',
      }}
    >
      {reward}
    </div>
  );
};
