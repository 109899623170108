import { ChangeEventHandler, FC, FormEventHandler } from 'react';
import { Config } from '../types';

interface ConfigDisplayProps {
  config: Config;
  setConfig(config: Config): void;
}

export const ConfigDisplay: FC<ConfigDisplayProps> = ({
  config,
  setConfig,
}) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };

  const PeriodConfig = () => {
    const forms = config.periodConfigs.map((v, i) => {
      const onChangePrice =
        (i: number): ChangeEventHandler<HTMLInputElement> =>
        (e) => {
          const newConfig = {
            ...config,
          };
          newConfig.periodConfigs[i].price = Number(e.target.value);
          setConfig(newConfig);
        };
      const onChangeStartMinute =
        (i: number): ChangeEventHandler<HTMLInputElement> =>
        (e) => {
          const newConfig = {
            ...config,
          };
          newConfig.periodConfigs[i].startCount =
            (newConfig.periodConfigs[i].startCount % 60) +
            Number(e.target.value) * 60;
          setConfig(newConfig);
        };
      const onChangeStartSecond =
        (i: number): ChangeEventHandler<HTMLInputElement> =>
        (e) => {
          const newConfig = {
            ...config,
          };
          newConfig.periodConfigs[i].startCount =
            Math.floor(newConfig.periodConfigs[i].startCount / 60) * 60 +
            Number(e.target.value);
          setConfig(newConfig);
        };

      const input = (
        <div key={`per-second-${i}`}>
          残り
          <input
            key={`per-second-start-minute-${i}`}
            value={Math.floor(v.startCount / 60)}
            type="number"
            min="0"
            onChange={onChangeStartMinute(i)}
          />
          ：
          <input
            key={`per-second-start-second-${i}`}
            value={v.startCount % 60}
            type="number"
            min="0"
            onChange={onChangeStartSecond(i)}
          />
          から
          <input
            key={`per-second-price-${i}`}
            value={v.price}
            type="number"
            onChange={onChangePrice(i)}
          />
          円
        </div>
      );
      return input;
    });
    return <form onSubmit={handleSubmit}>{forms}</form>;
  };

  return <div>{PeriodConfig()}</div>;
};
